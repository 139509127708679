/*
@import "../bower_components/bootstrap/less/bootstrap.less";

@import "../bower_components/font-awesome/less/font-awesome.less";
@fa-font-path: "../bower_components/font-awesome/fonts";
*/


//@import "../dialogs/aimdoc_viewcl/viewcl.less";

//@import "../css/bootstrap.min.css";



/* Required for Angular UI Bootstrap */
.nav, .pagination, .carousel a { cursor: pointer; }

/* Request from Alya 5/26/2021: she would like the items in the menus (selects) to have more whitespace between the items vertically */
.form-control option { font-size:18px;}

/* *****CHAMP DROPZONE***** */
.dropzone {
  border: 1px solid #256EB8;
  /*min-height: 360px;*/
  height:40px;
  width:160px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  /*background: blue;*/
  padding: 0px;
  margin:0px;
}

.divoutline {
  border: 1px solid #d4d4d4;
  /*min-height: 360px;*/
  -webkit-border-radius: 5px;
  border-radius: 5px;
  /*background: blue;*/
  padding: 5px;
  margin:5px;
}

/*
Sticky Footer Solution
by Steve Hatcher
http://stever.ca
http://www.cssstickyfooter.com
*/
/*
* {margin:0;padding:0;}
*/

/* must declare 0 margins on everything, also for main layout components use padding, not
vertical margins (top and bottom) to add spacing, else those margins get added to total height
and your footer gets pushed down a bit more, creating vertical scroll bars in the browser */

html, body {height: 100%;}

#wrap {min-height: 100%;}

#main {overflow:auto;
  padding-bottom: 25px;}  /* must be same height as the footer */

#footer {position: relative;
  margin-top: -25px; /* negative value of footer height */
  height: 25px;
  clear:both;}

/*Opera Fix*/
body:before {/* thanks to Maleika (Kohoutec)*/
  content:"";
  height:100%;
  float:left;
  width:0;
  margin-top:-32767px;/* thank you Erik J - negate effect of float*/
}

.gridStyle {
  border: 1px solid rgb(212,212,212);
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  max-width: 600px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.multiselect_item {
  -moz-border-radius: 0.3em;     /* for mozilla-based browsers */
  -webkit-border-radius: 0.3em;  /* for webkit-based browsers */
  border-radius: 0.3em;          /* theoretically for *all* browsers
                                    dependant on implementation of CSS3 */
  border: 2px solid;
 // background-color: #808080;
 //  color: #eee;
  margin-right: 10px;
  padding: 4px;
}

.ngCell  {
  display : table-cell;
  height: auto !important;
  overflow:visible;
  position: static;
}

.ngRow {
  display : table-row;
  height: auto !important;
  position: static;
}

.ngCellText{
  height: auto !important;
  white-space: normal;
  overflow:visible;
}

/* mixin for applying browser vendor prefixes to a CSS property */
/* @{prop} syntax does not work with our ancient grunt-contrib-less ~0.8
.vendor-prefix(@prop, @val) {
  @{prop}: @val;
  -webkit-@{prop}: @val;
  -khtml-@{prop}: @val;
  -moz-@{prop}: @val;
  -ms-@{prop}: @val;
  -o-@{prop}: @val;
}
*/

    // Make the Angular Material select controls taller when they open
.mat-select-panel {
    max-height: 1200px !important;
}

// Make the ovals around each field easier to see (it's 0.1 opacity by default)
.mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0,0,0,0.8) !important;
}

/* for allowing text selection in ui-grid (and hence csListControl) when enableRowSelection is true
   by overriding .ui-grid-disable-selection which ui-grid uses to disable text selection
   (https://stackoverflow.com/questions/33912587/angular-ui-grid-can-not-copy-cell-text) */
.ui-grid-selectable .ui-grid-disable-selection {
  -webkit-touch-callout: default;
  user-select: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -o-user-select: text;
  //.vendor-prefix(@prop: user-select; @val: text);
  cursor:auto;
}

/* IMPORTANT

You also need to include this conditional style in the <head> of your HTML file to feed this style to IE 6 and lower and 8 and higher.

<!--[if !IE 7]>
	<style type="text/css">
		#wrap {display:table;height:100%}
	</style>
<![endif]-->

*/

/* The following was added by Angular Material */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
