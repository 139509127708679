/* Fix for Bootstrap 3 with Angular UI Bootstrap */

.modal {
  display: block;
}
.explanation h4{text-decoration:underline; }
.semihidden {top:0; left:0; height:100%; width:100%; opacity: 0.4; background: white;}
.semihiddenimg {top:0; left:0; height:100; width:100; opacity: 0.4; background: white;}

/* Custom dialog/modal headers */

.dialog-header-error { background-color: #d2322d; }
.dialog-header-wait { background-color: #428bca; }
.dialog-header-notify { background-color: #eeeeee; }
.dialog-header-confirm { background-color: #333333; }
.dialog-header-error span, .dialog-header-error h4,
.dialog-header-wait span, .dialog-header-wait h4,
.dialog-header-confirm span, .dialog-header-confirm h4 { color: #ffffff; }

/* Ease Display */

.pad { padding: 25px; }