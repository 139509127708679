.completedReminder {
    text-decoration: line-through;
}

.overdueReminder {
    color: red;
    font-weight: bold;
}

input.ng-invalid {
    background-color: pink;
}

.highlightheading th:hover {
    background-color: lightgray;
}
