#navbarlogo {
  font-size:24pt;  /*Add this so that 1em is the same accross the divs*/

  padding-right:5px;
 // display:inline-block;
  float: left;
  
  vertical-align:bottom;
}

  /* For AIMDoc, we need the NavBar to lock at the top of the screen when the rest of the page scrolls. */
.navbar {
  position: fixed;
  top: 0px;
  z-index:100;
  width:100%;
}

.dropdown-disabled {
  color: #cccccc;
}