	/* Override Bootstrap defaults for its tabs. We want ours centered. */
.nav-tabs > li, 
.nav-pills > li {
    float:none;
    display:inline-block;
    *display:inline; /* ie7 fix */
     zoom:1; /* hasLayout ie7 trigger */
}
.nav-tabs, 
.nav-pills {
    text-align:center;
}


/* Don't want text area inside Submit Changes... dialog to be resizable horizontally. */
textarea 
{ 
	resize:vertical; 
}

.AIMDocTable {
}

.wordbreak {
    word-break: break-all;
}

.AIMDocTable table, 
.AIMDocTable th, 
.AIMDocTable td {
    border: 1px solid #bbb;
    table-layout: auto; /* auto, fixed */
    overflow: hidden;
    border-collapse: collapse;
}
.AIMDocTable th {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 8px; /* Don't change this or you'll mess up column dragging */
    text-align: left;
    color: #000;
    background-color: #ddd;
    border: 3px outset;
}
/* Note: this only affects the table header. Everything else is set in JavaScript by createSubtestsTableTRs() */
.TestsSubTable,
.TestsSubTable th,
.TestsSubTable tr {
/*	color: #ff0000;*/	/* Text color */
	background-color: powderblue;
	border-color: gray;
}


.AIMDocTable td {
    padding-left: 12px;
}
.AIMDocTable td.bold {
    font-weight: bold;
}
.AIMDocTable span {
    margin-left: 24px;
}
.AIMDocTable td#editing,
.AIMDocTable div#editing {
    color: black;
    background-color: white;
}
.AIMDocTable tr#justAdded,
.TestsSubTableTR tr#justAdded {
    background-color: yellow;
}
.AIMDocTable tr:nth-child(2n+0) {
    background-color: #F5F5F5;
}
.AIMDocTable tr:nth-child(odd) {
    background-color: #F2F2F2;
}
.AIMDocTable tr.add {
	background-color: lawngreen;
}
.AIMDocTable tr.delete {
	background-color: #ff9999;
	text-decoration: line-through;
}
.AIMDocTable tr.original {
/*	background-color: skyblue;*/
}
.AIMDocTable tr.error {
    background-color: #ff9999;
}
.AIMDocTable td.modify {
	background-color: skyblue;
}
.AIMDocTable .tableButton {
  /*  height: auto;*/
}

/*	Doesn't seem to change anything:
.AIMDocTable tr:hover {
    background-color: #abc;
}*/

    /* For the context menu drop-down */
.position-fixed {
  position: fixed;
}

.showMenu {
    z-index:1000;
    position: absolute;
    top: 100px;
    left: 100px;
}

.hideMenu {
   display: none;
}

.popupMenu {
    box-shadow: 5px 5px 3px #888888;
    border-radius: 5px;

    background-color:#FFFFFF;
    border: 1px solid #d4d4d4;

    cursor: pointer;
}

   /* The #myname is what you use in id="myname" in HTML for the parent container. Then all child elements, such as 'ul' in this case,
   get the styling specified here for each of those elements. */
.popupMenu ul {
       /* Don't show underlines for links */
    text-decoration: none;
    list-style-type: none;
    list-style: none;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 8px;
    padding-bottom: 8px;

    display: block;
}

.popupMenu li:hover {
    background-color: #eeeeee;
}

.popupMenu li {
    text-indent: 18px;
    margin-right: 0px;
    cursor: pointer;
}

.popupMenu span {
    margin-right: 24px;
}

.popupMenu a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}


#divider {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 10px;
    width: 100%;
    border-bottom: 1px solid #d4d4d4;
    overflow: hidden;
    cursor: default;
}

#dividerEnd {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 10px;
    width: 100%;
    cursor: default;
}
